import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from 'src/components/Layout'
import SEO from 'src/components/seo'
import RedLine from 'src/components/RedLine'
import CollapseItem from 'src/components/Collapse/CollapseItem'
import Popup from 'reactjs-popup'
import close_btn from 'src/images/prumdrt2020/close_btn.svg'
import close_btn_white from 'src/images/prumdrt2020/close_btn_white.svg'
import RunRegisterNowButton from 'src/components/Button/RunRegisterNowButton'
import 'src/styles/home.scss'
import 'src/styles/run.scss'
import background_title from 'src/images/prumdrt2020/bg_title.png'
import Video from 'src/components/Video'
import BoxShareWithPhoto from 'src/components/BoxShareWithPhoto'

export default ({ data }) => {
  const run_content = data.allRestApiApiVirtualRun.edges[0].node.content
  const localeClass = data.allRestApiApiVirtualRun.edges[0].node.locale

  const [openPopup, setOpenPopup] = useState(false)

  const [openVideoPopup, setOpenVideoPopup] = useState(false)

  const renderWelcomeSection = () => {
    return (
      <div
        className="welcome-section"
        style={{ backgroundImage: `url(${run_content.about_background})` }}
      >
        <div className="welcome-content">
          {/* <p className="virtual-event-text">{run_content.virtual_run}</p> */}
          <p className="welcome-introduction">
            {parse(run_content.run_description)}
          </p>
        </div>
        {/* <RedLine
          containerStyles={{ justifyContent: 'flex-start', height: 12.5 }}
        /> */}
      </div>
    )
  }

  const renderShareSection = () => {
    return (
      <div className="section-share-photo">
        <div className="title-group">
          <p className="text-with-hashtag">
            {parse(run_content.ar_section_description)}
          </p>
        </div>
        <div
          className="image-group"
          style={{
            backgroundImage: `url(${run_content.ar_sticker_background})`
          }}
        >
          {run_content.ar_stickers.map(box => {
            return (
              <BoxShareWithPhoto
                box_content={box}
                locale={localeClass}
                text_scan_qr_code_text={run_content.scan_qr_code_text}
              />
            )
          })}
        </div>
      </div>
    )
  }

  const renderContactSection = () => (
    <div
      className="contact-section"
      id="contact"
      style={{ backgroundImage: `url(${run_content.contact_background})` }}
    >
      {/* <RedLine /> */}
      <div className="contact-content">
        <div className="contact-title"> {run_content.contact}</div>
        <div className="contact-details">
          {parse(run_content.contact_content)}
        </div>
      </div>
    </div>
  )

  const renderHowItWorks = () => (
    <div
      className="how-it-works-section"
      style={{ backgroundImage: `url(${run_content.how_it_work_background})` }}
    >
      <div className="how-it-works-content">
        <div className="section-title">
          <div
            className="title-bg-run"
            style={{ backgroundImage: `url(${background_title})` }}
          >
            {run_content.how_it_works}
          </div>
        </div>
        <div className="how-it-work-content">
          {run_content.how_it_works_content.map(item => (
            <div className="section-item">
              <div className="section-bold-text red-text flex-text">
                <span className="equal">=========</span> {item.title}{' '}
                <span className="equal">=========</span>{' '}
              </div>
              <div className="section-normal-text text-bold">
                {parse(item.content)}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <RedLine
        containerStyles={{ justifyContent: 'flex-start', height: 12.5 }}
      /> */}
    </div>
  )

  const renderRules = () => (
    <div
      className="rules-section"
      style={{ backgroundImage: `url(${run_content.rules_background})` }}
    >
      <div className="rules-content">
        <div className="section-title">
          <div
            className="title-bg-run rules"
            style={{ backgroundImage: `url(${background_title})` }}
          >
            {run_content.rules}
          </div>
        </div>
        <ul>
          {run_content.rule_contents.map(item => (
            <li key={item.id} className="section-normal-text">
              {parse(item.title)}
              {item.read_more && (
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault()
                    setOpenPopup(true)
                    return false
                  }}
                >
                  {item.read_more}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
      {/* <RedLine
        containerStyles={{ justifyContent: 'flex-start', height: 12.5 }}
      /> */}
    </div>
  )

  const renderPeopleAlsoAsk = () => (
    <div className="people-also-ask-section">
      <div className="people-also-ask-content">
        <div className="section-title">
          <div
            className="title-bg-run rules"
            style={{ backgroundImage: `url(${background_title})` }}
          >
            {run_content.people_also_ask}
          </div>
        </div>
        <div>
          <div className="collapse">
            {run_content.people_also_ask_content.map(item => (
              <CollapseItem title={item.question} key={item.id}>
                {parse(item.answer)}
                {item.read_more && (
                  <a
                    href=""
                    onClick={e => {
                      e.preventDefault()
                      setOpenPopup(true)
                      return false
                    }}
                  >
                    {item.read_more}
                  </a>
                )}
              </CollapseItem>
            ))}
          </div>
        </div>
      </div>
      {/* <RedLine
        containerStyles={{ justifyContent: 'flex-start', height: 12.5 }}
      /> */}
    </div>
  )

  const renderVideoPopup = () => (
    <Popup
      open={openVideoPopup}
      closeOnDocumentClick
      onClose={() => setOpenVideoPopup(false)}
      contentStyle={{
        width: '95%',
        maxWidth: 1732,
        borderRadius: 12
      }}
      overlayStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      className="run-video-popup"
      modal
      lockScroll
      repositionOnResize
    >
      <div className="run-video-popup">
        <div className="speaker-popup-container">
          <img
            src={close_btn_white}
            className="close-btn-white"
            onClick={() => setOpenVideoPopup(false)}
          />
          <div className="videoWrapper">
            <Video videoSrcURL={run_content.check_out_video} className="test" />
          </div>
        </div>
      </div>
    </Popup>
  )

  const renderGPSTrackingPopup = () => (
    <Popup
      open={openPopup}
      closeOnDocumentClick
      onClose={() => setOpenPopup(false)}
      contentStyle={{
        width: '95%',
        maxWidth: 1024,
        borderRadius: 12
      }}
      className="gps-tracking-popup"
      modal
      lockScroll
      repositionOnResize
    >
      <div className="gps-tracking-container">
        <div className="gps-tracking-title-section">
          <div className="gps-tracking-title section-bold-text red-text">
            {run_content.what_tracking}
          </div>
          <img
            src={close_btn}
            className="close-btn"
            onClick={() => setOpenPopup(false)}
          />
        </div>
        <div className="gps-tracking-content-section">
          <div className="section-normal-text">
            {parse(run_content.all_gps_based_app)}
          </div>
          <div className="gps-apps-group">
            {run_content.gps_track_content.map(item => (
              <div className="gps-app-item">
                <img src={item.icon} alt="" />
                <div className="gps-app-name">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Popup>
  )

  return (
    <Layout locale={'id'} isRunPage>
      <SEO title="Virtual Run" />
      <div className="run-page">
        <div className="home-banner-wrapper">
          <img src={run_content.home_banner} alt="" />
          <div className="blend-line"></div>
        </div>
        {renderWelcomeSection()}
        {renderShareSection()}

        {renderGPSTrackingPopup()}
        {renderVideoPopup()}
      </div>
    </Layout>
  )
}

export const GatsbyQuery = graphql`
  {
    allRestApiApiVirtualRun(filter: { locale: { eq: "id" } }) {
      edges {
        node {
          content {
            ar_stickers {
              title
              qr_code_url
              qr_code
              image
            }
            scan_qr_code_text
            virtual_run
            run_description
            check_out_video
            check_out_video_text
            share_virtual_run_photo
            contact
            contact_content
            register_now
            home_banner
            about_background
            contact_background
            ar_sticker_background
            ar_section_description
            how_it_work_background
            how_it_works
            how_it_works_content {
              title
              content
            }
            rules
            rule_contents {
              id
              title
              read_more
            }
            people_also_ask
            people_also_ask_content {
              id
              question
              answer
              read_more
            }
            what_tracking
            all_gps_based_app
            gps_track_content {
              icon
              name
            }
          }
          locale
        }
      }
    }
  }
`
